export const clearAdsenseBlocks = () => {
    document.querySelectorAll('iframe')
        .forEach((domElement) => {
            domElement.remove();
        });

    document.querySelectorAll('.adsbygoogle')
        .forEach((domElement) => {
            domElement.remove();
        });
    document.getElementById('script-adsbygoogle')?.remove();
    document.getElementById('google_esf')?.remove();

    const script = document.createElement('script');
    script.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4873470855788285";
    script.async = true;
    script.crossOrigin = 'anonymous';
    script.id = "script-adsbygoogle";
    document.body.appendChild(script);
};