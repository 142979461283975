import { RouteLocationNormalizedLoaded } from "vue-router";

export const setMeta = (seo: object, config: object, route: RouteLocationNormalizedLoaded) => {
    const meta: object = {
        title: seo.metaTitle + ' | allrecipes.club',
        ogTitle: seo.metaTitle,
        description: seo.metaDescription,
        ogDescription: seo.metaDescription,
        ogImage: '',
        keywords: seo.keywords,
    };
    const head: object = {
        link: [
            {
                rel: 'canonical',
                href: config.public.baseUrl + route.path,
            }
        ],
        script: [
            {
                innerHTML: '[]',
                type: 'application/ld+json',
                id: 'sd'
            },
        ],
    };

    if (seo?.metaImage !== undefined && seo?.metaImage?.data?.attributes?.url !== undefined && seo.metaImage.data.attributes.url) {
        meta.ogImage = config.public.mediaBaseUrl + seo.metaImage.data.attributes.url;
    }

    if (seo?.canonicalURL !== undefined && seo.canonicalURL) {
        head.link[0].href = seo.canonicalURL;
    }

    if (seo?.structuredData !== undefined && seo?.structuredData !== null) {
        head.script[0].innerHTML = JSON.stringify(seo.structuredData);
    }

    if (seo?.metaRobots !== undefined && seo.metaRobots) {
        meta.robots = seo.metaRobots;
    }

    useSeoMeta(meta);
    useHead(head);
}